export const APP_ROUTES = {
  Root: '/',
  Home: '/home',
  Admin: '/admin',
  Communities: '/communities',
  MyCommunities: '/my-communities',
  Login: '/login',
  SignUp: '/sign-up',
  Event: '/evt',
  Forms: '/form',
  FormBuilder: 'form/build',
  FormSettings: '/form/settings',
  FormSubmissions: '/form/:formId/submissions',
  ForgotPassword: '/forgot-password',
  DiscoverGames: '/discover/games',
  DiscoverPlaces: '/discover/places',
  CreateGame: '/create',
  Profile: '/profile',
  MoreInfo: '/more-info',
  Messages: '/messages',
  Teams: '/teams',
  TeamsV3: '/teams-v3',
  TeamNotFound: '/team-not-found',
  Team: '/team',
  // TODO
  Place: '/place',
  Places: '/places',
  PlacesNew: '/places/new',
  PlacesEdit: '/places/edit',
  PlacesView: '/places/view',
  DiscoverPlayers: '/discover/players',
  Settings: '/settings',
  DiscoverTeams: '/discover/teams',
  CustomWebsite: '/custom-website',
  CreateTeam: '/teams/create',
  NewFeature: 'https://forms.gle/CAhCjH169VW6jy3q8',
  NewBug: 'https://forms.gle/DrSfkn7MrBrKX5Ft6',
  PaymentSuccess: '/payment-success',
  Transactions: '/transactions',
  ExternalTeams: '/external-teams',
  Auth: '/auth',
  Invoices: '/invoices',
};

export const TEAMS_ROUTES = {
  DASHBOARD: 'dashboard',
  ROSTER: 'roster',
  SCHEDULE: 'schedule',
  RSVPS: 'rsvps',
  UPLOADS: 'uploads',
  ANNOUNCEMENTS: 'announcements',
  ADMIN: 'admin',
  WEBSITE: 'website',
  ORGANIZER_WEBSITE: 'organizer-website',
  SURVEY: 'survey',
  CREATE_SURVEY: 'survey/create',
  SURVEY_FORM: (formId: string) => `${formId}/form`,
  TOPICS: 'topics',
  REVIEWS: 'reviews',
  ANALYTICS: 'analytics',
  EMAIL: 'email',
  MEMBERS: 'members',
};
